export const MenuData = [{
        english: "home",
        title: "首页",
        url: "/",
        submenu: [],
        menuShow: false,
        active: false,
    },
    {
        english: "about",
        title: "关于集团",
        url: "aboutGroup",
        menuShow: false,
        active: false,
        submenu: [{
                subtitle: "创始人致辞",
                suburl: "founderAddress",
            },
            {
                subtitle: "企业文化",
                suburl: "corporateCulture",
            },
            {
                subtitle: "组织架构",
                suburl: "organizational",
            },
        ],
    },
    {
        english: "business",
        title: "业务领域",
        url: "BusinessArea",
        menuShow: false,
        active: false,
        submenu: [],
    },
    {
        english: "dynamic",
        title: "企业动态",
        url: "enterpriseNews",
        menuShow: false,
        active: false,
        submenu: [{
                subtitle: "新闻动态",
                suburl: "list",
            },
            {
                subtitle: "员工风采",
                suburl: "staff",
            },
        ],
    },
    {
        english: "company",
        title: "旗下公司",
        url: "ItsCompanies",
        menuShow: false,
        active: false,
        submenu: [{
                subtitle: "北京有为信通",
                suburl: "bjCompanies",
            },
            {
                subtitle: "北京华星数聚",
                suburl: "hxCompanies",
            },
            {
                subtitle: "西安有为信通",
                suburl: "xaCompanies",
            },
            {
                subtitle: "西宁有为信息",
                suburl: "xnCompanies",
            },
            {
                subtitle: "杭州卓远数聚",
                suburl: "hzCompanies",
            },
        ],
    },
    {
        english: "contact",
        title: "联系祥业",
        url: "contactUs",
        menuShow: false,
        active: false,
        submenu: [{
                subtitle: "北京办公区",
                suburl: "bjcontactUs",
            },
            {
                subtitle: "西安办公区",
                suburl: "xacontactUs",
            },
            {
                subtitle: "杭州办公区",
                suburl: "hzcontactUs",
            },
            {
                subtitle: "西宁办公区",
                suburl: "xncontactUs",
            },
        ],
    },
]