import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Home",
    component: Home,
},
// 关于集团
{
    path: "/aboutGroup",
    name: "aboutGroup",
    component: () =>
        import("../views/aboutGroup/aboutGroup.vue"),
},
{
    path: "/corporateCulture",
    name: "aboutGroup",
    component: () =>
        import("../views/aboutGroup/corporateCulture.vue"),
        meta: { keepAlive: true, parentPath: 'aboutGroup' }
},
{
    path: "/founderAddress",
    name: "founderAddress",
    component: () =>
        import("../views/aboutGroup/founderAddress.vue"),
        meta: { keepAlive: true, parentPath: 'aboutGroup' }
},
{
    path: "/organizational",
    name: "organizational",
    component: () =>
        import("../views/aboutGroup/organizational.vue"),
        meta: { keepAlive: true, parentPath: 'aboutGroup' }
},
// 旗下公司
{
    path: "/ItsCompanies",
    name: "ItsCompanies",
    component: () =>
        import("../views/ItsCompanies/ItsCompanies.vue"),
        
},
{
    path: "/bjCompanies",
    name: "bjCompanies",
    component: () =>
        import("../views/ItsCompanies/bjCompanies.vue"),
        meta: { keepAlive: true, parentPath: 'ItsCompanies' }
},
{
    path: "/hzCompanies",
    name: "hzCompanies",
    component: () =>
        import("../views/ItsCompanies/hzCompanies.vue"),
        meta: { keepAlive: true, parentPath: 'ItsCompanies' }
},
{
    path: "/xnCompanies",
    name: "xnCompanies",
    component: () =>
        import("../views/ItsCompanies/xnCompanies.vue"),
        meta: { keepAlive: true, parentPath: 'ItsCompanies' }
},
{
    path: "/xaCompanies",
    name: "xaCompanies",
    component: () =>
        import("../views/ItsCompanies/xaCompanies.vue"),
        meta: { keepAlive: true, parentPath: 'ItsCompanies' }
},
{
    path: "/hxCompanies",
    name: "hxCompanies",
    component: () =>
        import("../views/ItsCompanies/hxCompanies.vue"),
        meta: { keepAlive: true, parentPath: 'ItsCompanies' }
},
// 业务领域
{
    path: "/BusinessArea",
    name: "BusinessArea",
    component: () =>
        import("../views/BusinessArea/BusinessArea.vue"),
},
{
    path: "/enterpriseNews",
    name: "enterpriseNews",
    component: () =>
        import("@/views/enterpriseNews/enterpriseNews.vue"),
        children: [
        {
        path: "/",
        component: () =>
            import("@/views/enterpriseNews/enterpriseNewsHome.vue"),
        meta: { keepAlive: true, parentPath: 'enterpriseNews' }

    }, {
        path: "/Housewarming",
        component: () =>
            import("@/views/enterpriseNews/Housewarming.vue"),
        meta: { keepAlive: true, parentPath: 'enterpriseNews' }

    },
    {
        path: "/tangshan",
        component: () =>
            import("@/views/enterpriseNews/tangshan.vue"),
        meta: { keepAlive: true, parentPath: 'enterpriseNews' }

    },
    {
        path: "/tsvisit",
        component: () =>
            import("@/views/enterpriseNews/tsvisit.vue"),
        meta: { keepAlive: true, parentPath: 'enterpriseNews' }

    },
     {
        path: "/Thankyoumeeting",
        component: () =>
            import("@/views/enterpriseNews/Thankyoumeeting.vue"),
        meta: { keepAlive: true, parentPath: 'enterpriseNews' }

    },
    {
        path: "/lecture",
        component: () =>
            import("@/views/enterpriseNews/lecture.vue"),
        meta: { keepAlive: true, parentPath: 'enterpriseNews' }

    },
    {
        path: "/Imageexhibition",
        component: () =>
            import("@/views/enterpriseNews/Imageexhibition.vue"),
        meta: { keepAlive: true, parentPath: 'enterpriseNews' }

    },
    {
        path: "/list",
        name:"list",
        component: () =>
            import("@/views/enterpriseNews/list.vue"),
        meta: { keepAlive: true, parentPath: 'enterpriseNews' }

    },
    {
        path: "/Thankyouletter",
        component: () =>
            import("@/views/enterpriseNews/Thankyouletter.vue"),
        meta: { keepAlive: true, parentPath: 'enterpriseNews' }

    },
    {
        path: "/reward",
        component: () =>
            import("@/views/enterpriseNews/reward.vue"),
        meta: { keepAlive: true, parentPath: 'enterpriseNews' }

    },
    {
        path: "/slogan",
        component: () =>
            import("@/views/enterpriseNews/slogan.vue"),
        meta: { keepAlive: true, parentPath: 'enterpriseNews' }

    },
    {
        path: "/staff",
        component: () =>
            import("@/views/enterpriseNews/staff.vue"),
        meta: { keepAlive: true, parentPath: 'enterpriseNews' }

    },
    {
        path: "/employeewelfare",
        component: () =>
            import("@/views/enterpriseNews/employeewelfare.vue"),
        meta: { keepAlive: true, parentPath: 'enterpriseNews' }

    },
    {
        path: "/stafftraining",
        component: () => import("@/views/enterpriseNews/stafftraining.vue"),
        meta: { keepAlive: true, parentPath: 'enterpriseNews' }


    }
    ]

},
// 联系祥业
{
    path: "/contactUs",
    name: "contactUs",
    component: () =>
        import("../views/contactUs/contactUs.vue"),
    children: [{
        path: "/",
        component: () =>
            import("@/views/contactUs/xycontactUs.vue"),
            meta: { keepAlive: true, parentPath: 'contactUs' }
    },
    {
        path: "/bjcontactUs",
        component: () =>
            import("@/views/contactUs/bjcontactUs.vue"),
            meta: { keepAlive: true, parentPath: 'contactUs' }
    },
    {
        path: "/xacontactUs",
        component: () =>
            import("@/views/contactUs/xacontactUs.vue"),
            meta: { keepAlive: true, parentPath: 'contactUs' }
    },
    {
        path: "/xncontactUs",
        component: () =>
            import("@/views/contactUs/xncontactUs.vue"),
            meta: { keepAlive: true, parentPath: 'contactUs' }
    },
    {
        path: "/hzcontactUs",
        component: () =>
            import("@/views/contactUs/hzcontactUs.vue"),
            meta: { keepAlive: true, parentPath: 'contactUs' }
    },
    ]
},
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

export default router;