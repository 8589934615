<template>
  <div>
    <header class="head">
      <div class="left">
        <router-link to="/">
          <div class="logo"></div>
        </router-link>
      </div>
      <div class="right">
        <div class="subtitle">
          <a href="http://www.uweicmall.com/" target="__blank">
            <span class="cplink"> 华为全产品线经销商 </span></a
          >
        </div>
        <div class="menu hamburger js-hover" @click="closeMenu">
          <span>菜单</span>

          <img
            class="menu-img"
            src="../assets/image/home/menu-icon.gif"
            alt=""
          />
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    likes: Number,
    isPublished: Boolean,
    commentIds: Array,
    author: Object,
    callback: Function,
    contactsPromise: Promise, // or any other constructor
  },
  data() {
    return {
      openOrClose: true,
    };
  },
  methods: {
    closeMenu() {
      // this.openOrClose = !this.openOrClose;
      this.$emit("closeMenu", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.head {
  position: absolute;
  z-index: 10;
  left: 0px;
  top: 50px;
  width: 100%;
  height: 100px;
  //   margin-top: 34px;
  .left {
    margin-left: 70px;
    float: left;
    width: 208px;
    height: 100px;
    .logo {
      width: 208px;
      height: 50px;
      margin-top: 18px;
      background: url(../assets/image/home/logo.png) no-repeat;
      cursor: pointer;
    }
  }
  .right {
    float: right;
    .subtitle {
      font-size: 20px;
      color: #666;
      // height: 100px;
      // line-height: 100px;
      margin-top: 44px;
      float: left;
      margin-right: 28px;
      .cplink {
        cursor: pointer;
        color: #666;
      }
      .cplink:hover {
        color: #6430d5;
      }
    }
    .menu {
      float: right;
      width: 82px;
      height: 100px;
      position: relative;
      cursor: pointer;
      .menu-img {
        width: 100%;
      }
      span {
        position: absolute;
        top: 45px;
        font-size: 18px;
        color: #fff;
        left: 43px;
      }
      // background: url(../assets/image/home/menu-icon.gif) no-repeat;
    }
  }
}
</style>
