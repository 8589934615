<template>
  <div>
    <div v-show="scrollTop > 1080" class="topreturn">
      <img @click="top" src="../assets/image/topreturn/Top.png" alt="" />
      <img
        src="../assets/image/topreturn/menu.png"
        alt=""
        @click="openMenu"
      />
      <img
        v-show="returnShow"
        src="../assets/image/topreturn/return.png"
        alt=""
        @click="returnSuperior"
      />
    </div>
  </div>
</template>

<script>
// import { MenuData } from "../api/menuData.js";
export default {
  data() {
    return {
      scrollTop: 0,
      returnShow: false,
      reuteUrl: "",
      routes: [],
      routePath:''
    };
  },
  watch: {
    $route(to, from) {
      let meta = this.$route.meta;
      if (Object.keys(meta) == 0) {
        this.returnShow = false;
      } else {
        this.returnShow = true;
      }
    },
  },
  created() {
    let meta = this.$route.meta;
    this.routePath = this.$route.path;
    if (Object.keys(meta) == 0) {
      this.returnShow = false;
    } else {
      this.returnShow = true;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll); //监听页面滚动
  },
  methods: {
    top() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    },
    windowScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
    },
    returnSuperior() {
      this.$router.go(-1);
    },
    openMenu(){
      this.$emit("closeMenu",true);
    }
  },
};
</script>

<style lang="scss">
.topreturn {
  position: fixed;
  right: 120px;
  bottom: 380px;
  width: 60px;
  z-index: 100;
  img {
    cursor: pointer;
    vertical-align: text-top;
  }
  img:nth-child(2) {
    position: absolute;
    top: 52px;
    left: 0;
  }
    img:nth-child(3) {
    position: absolute;
    top: 111px;
    left: 0;
  }
}
</style>