<template>
  <div>
    <!-- <transition name="el-zoom-in-top" @after-leave="transitionComplete"> -->
    <div v-show="menuShow" class="menu-box">
      <div @click="closeMenu(1)" class="close"></div>
      <div class="menu">
        <div class="menulist">
          <div
            @click="menuClick(item, index)"
            @mouseover="menuMouseover(item, index)"
            @mouseout="menuMouseout"
            v-for="(item, index) in menuList"
            :key="index"
            class="item"
          >
            <div class="english" style="">{{ item.english }}</div>
            <span class="item" :class="{ 'activ-item': item.active }">
              {{ item.title }}
              <el-collapse-transition>
                <ul
                 
                  class="submenu-box"
                  :class="{ 'submenu-box-active': item.active }"
                >
                  <li
                    @click.stop="subMenuClick(subitem, i)"
                    v-for="(subitem, i) in item.submenu"
                    :key="i"
                  >
                    {{ subitem.subtitle }}
                    <!-- <router-link :to="'/'+subitem.subtitle.url">{{ subitem.subtitle }}</router-link> -->
                  </li>
                </ul>
              </el-collapse-transition>
            </span>
          </div>
        </div>
      </div>
      <svg
        class="shape-overlays"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <path
          class="shape-overlays__path"
          d="M 0 100 C 50 100 50 100 100 100 V 100 H 0"
        ></path>
        <path
          class="shape-overlays__path"
          d="M 0 100 C 50 100 50 100 100 100 V 100 H 0"
        ></path>
        <path
          class="shape-overlays__path"
          d="M 0 100 C 50 100 50 100 100 100 V 100 H 0"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
import { MenuData } from "../api/menuData.js";
import ShapeOverlays from "../utils/menuMask.js";
export default {
  data() {
    return {
      url: "",
      menuShow: false,
      loadMenuList: [],
      menuList: MenuData,
      elmOverlay: "",
      currentPath: "",
    };
  },
  created() {
    let route = this.$route;
    this.currentPath = route.path;
    console.log(this.currentPath);
    let { meta } = route;
    // 判定当前路由
    if (Object.keys(meta) == 0) {
      this.refreshMenu();
      if (route.path == "/") {
        this.menuList[0].active = true;
      } else {
        this.refreshMenu();
        let str = route.path.substr(1);
        this.menuList.map((val) => {
          if (val.url === str) {
            val.active = true;
          }
        });
      }
    } else {
      this.refreshMenu();
      this.menuList.map((val) => {
        if (meta.parentPath === val.url) {
          val.active = true;
        }
      });
    }
  },
  mounted() {
    const elmHamburger = document.querySelector(".hamburger");
    this.elmOverlay = document.querySelector(".shape-overlays");
    const overlay = new ShapeOverlays(this.elmOverlay);
    elmHamburger.addEventListener("click", () => {
      if (overlay.isAnimating) {
        return false;
      }
      overlay.toggle();
    });
  },
  methods: {
    refreshMenu() {
      this.menuList.map((val) => {
        val.active = false;
      });
    },
    menuMouseover(item, i) {
      // this.menuList.map((val) => {
      //   val.menuShow = false;
      //   val.active = false;
      // });
      // item.menuShow = true;
      // item.active = true;

      // const itemArr = document.querySelectorAll(".item");
      // console.log(itemArr);
    },
    menuClick(item, i) {
      if (item.url) {
        console.log(this.currentPath, item.url);
        if (this.currentPath != item.url) {
          let str = this.currentPath.substr(1);
          if (str == item.url) {
            console.log(22);
            this.url = "";
          } else {
            console.log(11);
            this.url = item.url;
          }
        } else {
          console.log(33);
          this.url = "";
        }

        this.closeMenu();
      } else {
        this.closeMenu();
      }
    },
    subMenuClick(item, i) {
      if (item.suburl) {
        let str = this.currentPath.substr(1);
        console.log(str , item.suburl);
        if (str == item.suburl) {
          console.log(222);
          this.url = "";
        } else {
          console.log(111);
          this.url = item.suburl;
        }
        this.closeMenu();
      }
    },
    menuMouseout() {
      this.menuList.map((val) => {
        val.menuShow = false;
      });
    },
    closeMenu(a) {
      if(a){
        this.url="";
      }
      let _this = this;
      // if (this.url) {
        const overlay = new ShapeOverlays(this.elmOverlay, _this, this.url);
        if (overlay.isAnimating) {
          return false;
        }
        overlay.toggle();
        this.currentPath="";
      // } else {
      //   const overlay = new ShapeOverlays(this.elmOverlay, _this, this.url);
      //   if (overlay.isAnimating) {
      //     return false;
      //   }
      //   overlay.toggle();
      // }
    },
  },
  destroyed() {
    // this.url = "";
  },
};
</script>

<style lang="scss" scoped>
ul li {
  list-style: none;
}
.menu-box {
  transition: all 0.6s ease-out;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  opacity: 1;
  background-color: #fff;
  background-size: 100% 100%;
  background-image: url("../assets/image/home/menubg.png");
  .close {
    width: 52px;
    height: 30px;
    background: url("../assets/image/home/close.png") no-repeat;
    position: absolute;
    top: 40px;
    right: 46px;
    cursor: pointer;
  }
  .menu {
    padding: 348px 206px 0 186px;
    font-size: 44px;
    font-family: IOS9W4;
    color: #878caa;

    .menulist {
      display: flex;
      justify-content: space-between;
      .item {
        cursor: pointer;
        .english {
          display: flex;
          justify-content: space-between;
          font-size: 30px;
          font-family: IOS9W4;
          color: #878caa;
          height: 30px;
          margin-bottom: 30px;
          text-align: left;
        }
        .activ-item {
          color: #6c35e4;
        }
        position: relative;
        height: 42px;
        .submenu-box {
          display: none;
          position: absolute;
          left: 0px;
          height: 0;
          overflow: hidden;
          padding-top: 38px !important;
          
          // top: 138px;
          li {
            width: auto;
            height: 28px;
            margin-bottom: 48px;
            line-height: 28px;
            text-align: left;
            font-size: 28px;
            font-family: IOS9W4;
            background: transparent;
            color: #333333;
            z-index: 111;
            cursor: pointer;
          }
        }
        .submenu-box-active {
          position: absolute;
          
          left: 0px;
          
          // top: 88px;
          li {
            width: 190px;
            height: 60px;
            background: #6c35e4;
            margin-bottom: 10px;
            line-height: 60px;
            text-align: center;
            padding: 0;
            border-radius: 4px;
            font-size: 28px;
            font-family: IOS9W4;
            color: #ffffff;
            z-index: 111;
            cursor: pointer;
          }
        }
      }
      .item:hover {
        color: #6c35e4;
      }
      .item:hover li {
        width: 190px;
        height: 60px;
        background: rgb(134, 78, 255);
        margin-bottom: 10px;
        line-height: 60px;
        text-align: center;
        padding: 0;
        border-radius: 4px;
        font-size: 28px;
        font-family: IOS9W4;
        color: #ffffff;
        z-index: 111;
        cursor: pointer;
      }
      .item:hover ul {
        position: absolute;
        left: 0px;
        display: block;
        height:auto;
        translate: 0.5s;
        // top: 88px;
      }
      .item ul:hover {
        position: absolute;
        left: 0px;
        
        // top: 88px;
      }
      .item ul li:hover {
        width: 190px;
        height: 60px;
        background:#6c35e4 ;
        margin-bottom: 10px;
        line-height: 60px;
        text-align: center;
        padding: 0;
        border-radius: 4px;
        font-size: 28px;
        font-family: IOS9W4;
        color: #ffffff;
        z-index: 111;
        cursor: pointer;
        
      }
    }
  }
}

.shape-overlays {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.shape-overlays.is-opened {
  pointer-events: auto;
}

.shape-overlays__path:nth-of-type(1) {
  fill: rgba(181, 146, 255, 0.45);
}
// 181  146  255
.shape-overlays__path:nth-of-type(2) {
  // fill: #763DED;
  fill: #9461ff;
}

.shape-overlays__path:nth-of-type(3) {
  // fill: #5824C6;
  fill: #7939ff;
}

.shape-overlays__path:nth-of-type(4) {
  // fill: #763DED;
  fill: #9461ff;
}
</style>