<template>
  <div>
    <div id="footer">
      <div class="logo">
        <img src="../assets/image/footer/footer-logo.png" alt="" />
      </div>
      <ul class="foot-nav">
        <li :class="{ active: isActive === 1 }" @click="changeClass(1)">
          <a data-link="foot-sitemap">网站地图</a>
        </li>
        <li :class="{ active: isActive === 2 }" @click="changeClass(2)">
          <a data-link="foot-contact-us">联系我们</a>
        </li>
        <li :class="{ active: isActive === 3 }" @click="changeClass(3)">
          <a data-link="foot-friendly-connection">友情链接</a>
        </li>
        <li :class="{ active: isActive === 4 }" @click="changeClass(4)">
          <a data-link="foot-legal-notices">法律声明</a>
        </li>
      </ul>
      <div class="record-box">
        <span>© 北京祥业科技控股集团有限公司</span>
        <a
          class="recore-ba"
          target="_blank"
          href="https://beian.miit.gov.cn/"
          >
          <img src="../assets/image/footer/ga-icon.png" />
           <span>京ICP备2021024061号</span>
        </a>
       
      </div>
      <div class="main-know-content" :style="sliderStyle">
        <div class="navigation">
          <span class="close" @click="close"></span>
          <div id="foot-sitemap" v-if="footSitemap">
            <div class="grid clearfix">
              <div class="grid_row">
                <h2> <router-link to="/aboutGroup">关于我们 </router-link></h2>
                <ul>
                  <li><router-link to="/aboutGroup">集团介绍</router-link></li>
                  <li><router-link to="/organizational">企业架构</router-link></li>
                </ul>
              </div>
              <div class="grid_row">
                <h2><router-link to="/BusinessArea">业务领域</router-link></h2>
                <ul>
                  <li><router-link to="/BusinessArea">业务领域</router-link></li>
                </ul>
              </div>
              <div class="grid_row">
                <h2><router-link to="/enterpriseNews">企业动态</router-link></h2>
                <ul>
                  <li><router-link to="/list">新闻动态</router-link></li>
                  <li><router-link to="/staff">员工风采</router-link></li>
                </ul>
              </div>
              <div class="grid_row">
                <h2><router-link to="/ItsCompanies">旗下公司</router-link></h2>
                <ul>
                  <li><router-link to="/bjCompanies">北京有为信通</router-link></li>
                  <li><router-link to="/hxCompanies">北京华星数聚</router-link></li>
                  <li><router-link to="/xaCompanies">西安有为信通</router-link></li>
                  <li>…</li>
                </ul>
              </div>
              <div class="grid_row">
                <h2><router-link to="/contactUs">联系祥业</router-link></h2>
                <ul>
                  <li><router-link to="/bjcontactUs">北京办公区</router-link></li>
                  <li><router-link to="/xacontactUs">西安办公区</router-link></li>
                  <li><router-link to="/hzcontactUs">杭州办公区</router-link></li>
                  <li>…</li>
                </ul>
              </div>
            </div>
          </div>
          <div id="foot-contact-us" v-if="footContact">
            <p class="foot-know-tit">联系我们</p>
            <ul class="foot-contact-us-list">
              <li>
                <p>
                  <span class="foot-con-us-label">Add：</span
                  ><span class="foot-con-us-in"
                    >北京市丰台区新村街道汽车博物馆东路6号院盈坤世纪D座8层</span
                  >
                </p>
                <p>
                  <span class="foot-con-us-label">Tel：</span
                  ><span class="foot-con-us-in">010-63721399</span>
                </p>
                <p>
                  <span class="foot-con-us-label">E-mail：</span
                  ><span class="foot-con-us-in">postmaster@uweic.com</span>
                </p>
              </li>
              <li>
                <p>
                  <span class="foot-con-us-label">P.C.：</span
                  ><span class="foot-con-us-in">100070</span>
                </p>
                <p>
                  <span class="foot-con-us-label">Fax：</span
                  ><span class="foot-con-us-in">010-63721399-833</span>
                </p>
                <p>
                  <span class="foot-con-us-label">Url：</span
                  ><span class="foot-con-us-in">xytech-group.com</span>
                </p>
              </li>
            </ul>
          </div>
          <div id="foot-friendly-connection" v-if="footConnection">
            <p class="foot-know-tit">友情链接</p>
            <ul class="foot-friendly-connection-list">
              <li>
                <span><a href="http://www.uweic.com/" target="_blank">北京有为信通科技发展有限公司</a></span>
              </li>
              <li><a href="http://www.bjhxsj.com/" target="_blank">北京华星数聚智慧科技有限公司</a></li>

              <li> <a href="http://www.sxuweic.com/" target="_blank">西安有为信通软件科技有限公司</a></li>
              <li><router-link to="/xaCompanies">西宁有为信息技术有限公司</router-link></li>
              <li style="margin-right: 33.33%"><a href="http://www.zjuweic.com/" target="_blank">杭州卓远数聚科技有限公司</a></li>
            </ul>
          </div>
          <div id="foot-legal-notices" v-if="footNotices">
            <p class="foot-know-tit">使用条款和隐私</p>
            <p class="foot-know-dest">
              本公司对其发行的或与合作公司共同发行的包括但不限于服务的全部内容及此网站上的材料拥有版权等知识产权，受法律保护。
              未经
              本公司书面许可，任何单位及个人不得以任何方式或理由对上述产品、服务、信息、材料的任何部分进行使用、複製、修改、抄录、
              传播或与其它产品捆绑使用、销售。
              凡侵犯本公司版权等知识产权的，本公司必依法追究其法律责任。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: 0,
      contentShow: false,
      footSitemap: false,
      footContact: false,
      footConnection: false,
      footNotices: false,
      sliderStyle: {
        height: "0px",
      },
    };
  },
  methods: {
    changeClass(i) {
      this.isActive = i;
      console.log(this.sliderStyle.height)
      // this.contentShow = true;
      this.sliderStyle.height = "260px";
   
      if (i == 1) {
        (this.footSitemap = true),
          (this.footContact = false),
          (this.footConnection = false),
          (this.footNotices = false);
      } else if (i == 2) {
        (this.footSitemap = false),
          (this.footContact = true),
          (this.footConnection = false),
          (this.footNotices = false);
      } else if (i == 3) {
        (this.footSitemap = false),
          (this.footContact = false),
          (this.footConnection = true),
          (this.footNotices = false);
      } else if (i == 4) {
        (this.footSitemap = false),
          (this.footContact = false),
          (this.footConnection = false),
          (this.footNotices = true);
      }
    },
    close() {
      (this.isActive = 0),
        // this.contentShow = false;
        (this.sliderStyle.height = "0px");
    },
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
ul li {
  list-style: none;
}
#footer {
  width: 100%;
  height: 221px;
  background-color: #121417;
  color: #999999;
  position: relative;
  /* margin-top: 260px; */
}
#footer .logo {
  width: 181px;
  float: left;
  margin: 78px 0 0 39px;
}
#footer .logo img {
  width: 100%;
  height: 50px;
}
#footer .foot-nav {
  float: left;
  margin: 101px 0 0 39px;
}
#footer .foot-nav li {
  float: left;
  padding: 0 20px;
  font-size: 16px;
  list-style: none;
  cursor: pointer;
}
#footer .foot-nav li.active {
  opacity: 1;
  color: #8249ff;
}
#footer .record-box {
  position: absolute;
  top: 100px;
  right: 70px;
  font-size: 12px;
  white-space: nowrap;
  color: #999999;
}
#footer .record-box .recore-ba {
  color: #999999;
  display: inline-block;
  margin-left: 20px;
  line-height: 22px;
  text-decoration: none;
}
#footer .record-box .recore-ba img {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 3px;
}

/* 默认foot */
.main-know-content {
  height: 260px;
  position: absolute;
  bottom: 100%;
  width: 100%;
  background-color: rgb(37, 40, 45);
  color: #999999;
  height: 0;
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  z-index: 8;
  overflow: hidden;
}
.main-know-content .navigation {
  width: 1024px;
  margin: 45px auto 0;
  position: relative;
}
.main-know-content .close {
  position: absolute;
  right: 0;
  top: -10px;
  display: block;
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 25px;
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.main-know-content .close:before {
  transform: rotate(45deg);
}
.main-know-content .close:after {
  transform: rotate(-45deg);
}
.main-know-content .close:after,
.main-know-content .close:before {
  content: "";
  position: absolute;
  width: 80%;
  top: 50%;
  left: 10%;
  height: 2px;
  margin-top: -6px;
  border-radius: 5px;
  background: #8249ff;
}
.main-know-content .close:hover {
  cursor: pointer;
  transform: rotate(90deg);
  transform-origin: 16px 13px;
}
.clearfix:after,
.clearfix:before {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
.main-know-content .grid .grid_row {
  width: 20%;
  float: left;
  line-height: 1.8;
}
.main-know-content .grid .grid_row h2 {
  font-size: 16px;
  padding-bottom: 20px;
  font-weight: 400;
  text-align: left;
}
.main-know-content .grid .grid_row a {
  color: #999999;
  /* opacity: 0.7; */
}
.main-know-content .grid .grid_row ul li {
  font-size: 14px;
  text-align: left;
}
/* 联系我们 */
.main-know-content .navigation .foot-know-tit {
  font-size: 16px;
  padding: 10px 0 20px;
  text-align: left;
}
.main-know-content .navigation .foot-contact-us-list {
  font-size: 14px;
}
.main-know-content .navigation .foot-contact-us-list li {
  float: left;
  text-align: left;
  /* padding: 0 10px 0 0; */
  width: 50%;
  line-height: 30px;
}
.main-know-content .navigation .foot-contact-us-list li .foot-con-us-label {
  float: left;
  width: 57px;
}
.main-know-content .navigation .foot-contact-us-list li span {
  display: inline-block;
}
/* 友情链接 */
.main-know-content .navigation .foot-know-tit {
  font-size: 16px;
  padding: 10px 0 20px;
}
.main-know-content .navigation .foot-friendly-connection-list li {
  /* padding: 0 10px 0 0; */
  text-align: left;
  display: inline-block;
  font-size: 14px;
  width: 33.33%;
  color: #999999;
}
.main-know-content .navigation .foot-friendly-connection-list li a{
  color: #999999;
}
/* 法律声明 */
.main-know-content .navigation .foot-know-tit {
  font-size: 16px;
  padding: 10px 0 20px;
}
.main-know-content .navigation .foot-know-dest {
  font-size: 14px;
  line-height: 2;
  text-align: left;
}



/* hover事件 */
#footer .foot-nav li:hover{
     color: #8249ff;
}
</style>

<style>
/* 适配手机 */
 @media (max-width: 520px) {
      #footer{
        position: absolute;
             bottom: -230px;
      }
         /* #footer{
           height: 300px;
         }
         #footer .logo{
          margin: 0 auto;
          float: none;
          padding-top: 26px;
         }
         #footer .foot-nav{
           margin: 0 auto;
           width: 100%;
         }
         #footer .foot-nav li{
           float: none;
           padding: 10px 20px;
          border-bottom: 1px dashed #f7f6f629;
         }
         #footer .record-box{
               bottom: 10px;
               right: 5%;
               left: 5%;
               top: auto;
        }
        .main-know-content .navigation{
          width: 100%;
        }
        .main-know-content .navigation .foot-know-tit ,.foot-know-dest{
          text-align: center;
        }
        .main-know-content .navigation .foot-contact-us-list li{
              width: 100%;
              line-height: 26px;
              font-size: 12px
        }
        .main-know-content .navigation .foot-contact-us-list li p{
           text-align: center;
        } */
        
         }
        
</style>