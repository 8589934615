import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import AOS from "aos";
import "aos/dist/aos.css";
// import './utils/rem.js'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
        ak: 'E4805d16520de693a3fe707cdc962045'
    })
    // import "fullpage.js/vendors/scrolloverflow";
    // import VueFullpage from "vue-fullpage.js";

// Vue.use(VueFullpage);
AOS.init({
    disable: 'mobile' // mobile、phone或tablet。
});
Vue.use(AOS);
AOS.init();
Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");