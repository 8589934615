<template>
  <router-view />
</template>

<style>
@import "./assets/css/normalize.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  font-size: 24px;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
legend,
button form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}
button {
  border: none;
}
body {
  text-align: center;
  font-size: 24px;
  min-height:100%;margin:0;padding:0;position:relative;
}

html{
    height: 100%;
}
 
    /* @media (min-width: 1921px) {
          body {
            zoom: 1;
          }
        }
    @media screen and (max-width:1380px) and (min-width:1029px) {
        body {
        zoom: 0.7;   
        }
      }
     @media (max-width: 1028px) {
          body {
            zoom: 0.64;
          }
        } */
</style>
<script>
// function bodyScale() {
//   var devicewidth = document.documentElement.clientWidth;
//   console.log(devicewidth);
//   var scale = devicewidth / 1920; // 分母——设计稿的尺寸
//   document.body.style.zoom = scale;
// }
// window.onload = window.onresize = function () {
//   bodyScale(); 
// };
</script>
<script>
  function bodyScale() {
  var devicewidth = document.documentElement.clientWidth;
console.log(devicewidth)
  var scale = devicewidth / 1920;  // 分母——设计稿的尺寸
  document.body.style.zoom = scale;
}
function isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
      }
window.onload = window.onresize = function () {
  if (isMobile()) {
        // alert("手机端");
        bodyScale();
        // this.$router.replace('/m_index');
      } else {
        // alert("pc端");
         bodyScale();
      }
};
       
</script>