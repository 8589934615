<template>
  <div class="home">
    <section class="firstscreen" ref="firstscreen">
      <Head @closeMenu="closeOpenMenu" />
      <el-carousel
        :interval="5000"
        @change="carouselChange"
        :autoplay="true"
        :height="height"
        arrow="always"
      >
        <el-carousel-item>
          <div class="img-box" style="100%">
            <img
              class="carouselImg"
              style="width: 100%; height: 100%"
              src="https://uweic-xy.obs.cn-north-1.myhuaweicloud.com/banner.png"
              alt=""
            />
            <div class="text-box">
              <p>{{ bannerArr[0].text }}</p>
              <p>{{ bannerArr[0].english }}</p>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="img-box" style="100%">
            <img
              class="carouselImg"
              style=""
              src="https://uweic-xy.obs.cn-north-1.myhuaweicloud.com/banner1.png"
              alt=""
            />
            <div class="text-box">
              <p>{{ bannerArr[1].text }}</p>
              <p>{{ bannerArr[1].english }}</p>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="img-box" style="100%">
            <img
              class="carouselImg"
              style=""
              src="https://uweic-xy.obs.cn-north-1.myhuaweicloud.com/banner2.png"
              alt=""
            />
            <div class="text-box">
              <p>{{ bannerArr[2].text }}</p>
              <p>{{ bannerArr[2].english }}</p>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="img-box" style="100%">
            <img
              style=""
              class="carouselImg"
              src="https://uweic-xy.obs.cn-north-1.myhuaweicloud.com/banner3.png"
              alt=""
            />
            <div class="text-box">
              <p>{{ bannerArr[3].text }}</p>
              <p>{{ bannerArr[3].english }}</p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </section>

    <section class="aboutxy">
      <div class="aboutxy-mask">
        <div class="aboutxy-center">
          <div
            class="title aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <span>关于祥业</span>
          </div>
          <div
            class="introduce aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <span>
              祥业科技控股集团是数字化整体解决方案提供商和运营服务商。祥业科技控股伴随着新经济的发展使命，在“十四五”规划“加快数字化发展 建设数字中国”的政策背景下。打造了DI(数字基础设施)和DA(数据应用)两大业务方向，以DI-Edge(智能边缘)、DI-Cloud(数字云底座)、DA数据应用(政府治理与服务侧：G2G、G2B、G2C）为主干的三大产品和解决方案体系。集团不断加大技术研发投入，持续强化边/云/用三位一体的综合竞争力，不断提升和扩大数字与智能平台的品牌影响力。
            </span>
          </div>
          <div class="but-box">
            <router-link to="/aboutGroup">
              <button class="learnmore">了解更多</button>
            </router-link>
          </div>
          <div
            class="title aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <span>发展策略</span>
          </div>
          <div
            class="strategy aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div class="item">
              <p>
                <img src="../assets/image/home/business.png" alt="" />
              </p>
              <p class="subtitle">商业管理与运营</p>
              <div>
                <span>
                  同时发展数据产业园区商业管理与运营、数字基础建设及数字应用系统建设关联性业务
                </span>
              </div>
            </div>
            <div class="item">
              <p>
                <img src="../assets/image/home/performance.png" alt="" />
              </p>
              <p class="subtitle">计算中心及数据中心</p>
              <div>
                <span>
                  以自主技术为主，突破大型计算机和大规模并行计算应用的关键技术，形成自主可控的技术体系和产品体系
                </span>
              </div>
            </div>
            <div class="item">
              <p>
                <img src="../assets/image/home/assets.png" alt="" />
              </p>
              <p class="subtitle">资产管理托管服务</p>
              <div>
                <span> 通过有效的标准化运营及有效运作实现企业利益从优 </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="groupnews">
      <div class="groupnews-center">
        <div
          class="title aos-init aos-animate"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <span>集团动态</span>
        </div>
        <p class="groupnews-line">
          <span class="center-line"></span>
        </p>
        <div class="groupnews-content">
          <div
            class="left aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div class="text">
              <p>厚积薄发--拓路前行</p>
              <p>2021年/07月</p>
            </div>
          </div>
          <div
            class="right aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div class="top">
              <div class="text">
                <p>行稳致远，进而有为</p>
                <p>2021年有为信通团建暨客户答谢会</p>
                <p>2021年/07/26 周日</p>
              </div>
            </div>
            <div class="bottom">
              <div class="text">
                <p>北京有为信通科技发展有限公司</p>
                <p>华为展厅揭幕暨公司乔迁仪式</p>
                <p>28/05/2021</p>
                <p>
                  祝贺有为信通乔迁之喜暨华为智能协作体验中心揭幕仪式成功举办！
                </p>
                <p>
                  <router-link to="/Housewarming">
                    <span>详情</span>
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <p class="more">
          <router-link to="/enterpriseNews">
            <img src="../assets/image/home/more.png" alt="" />
          </router-link>
        </p>
      </div>
    </section>
    <section class="corporateculture">
      <router-link to="/corporateCulture">
        <img src="../assets/image/home/downMore.png" alt="" />
      </router-link>
    </section>
    <Footer></Footer>
    <Topreturn @closeMenu="closeOpenMenu"></Topreturn>
    <Menu ref="menu" />
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Menu from "@/components/menu.vue";
import Footer from "@/components/footer.vue";
import Topreturn from "@/components/Topreturn.vue";
// import "../../node_modules/fullpage/build/fullpage";
// console.log(window.fullpage);
export default {
  name: "Home",
  data() {
    return {
      height: "",
      bannerArr: [
        {
          src: "assets/image/home/banner.png",
          text: "成为行业数字化转型的推动者",
          english: "To be a promoter of the industry's digital transformation",
        },
        {
          src: "../assets/image/home/banner2.png",
          text: "真诚、专业、创新、协作",
          english: "Sincere, professional, innovative cooperative",
        },
        {
          src: "../assets/image/home/banner1.png",
          text: "用心前行 诚信共赢 筑梦远航",
          english: "Leads the way the good faith altogether to win attentively",
        },
        {
          src: "../assets/image/home/banner3.png",
          text: "以客户为中心 以奋斗者为本 以价值为纲",
          english: "Customer-centered, striver-oriented, value-guided",
        },
      ],
    };
  },
  components: {
    Head,
    Menu,
    Footer,
    Topreturn,
  },
  mounted() {
    //图片自适应浏览器窗口大小
    // window.fullpage.init("#sectionContent");
    setTimeout(() => {
      let carouselImg = document.querySelectorAll(".carouselImg");
      carouselImg[0].style.cssText = "transform:scale(1.2)";
    }, 200);

    let firstscreen = this.$refs.firstscreen;
    function findSize(a) {
      a.height = window.innerHeight + "px";
      firstscreen.style.height = window.innerHeight + "px";
    }
    window.addEventListener("resize", () => findSize(this), false);
    window.addEventListener("onload", () => findSize(this), false);
    findSize(this);
  },
  methods: {
    carouselChange(val) {
      this.$nextTick(() => {
        let carouselImg = document.querySelectorAll(".carouselImg");
        if (val == 0) {
          carouselImg[carouselImg.length - 1].style.cssText =
            "transform:scale(1)";
          carouselImg[carouselImg.length - 1].style.cssText = "transition:0";
        } else {
          carouselImg[val - 1].style.cssText = "transform:scale(1)";
          carouselImg[val - 1].style.cssText = "transition:0";
        }
        carouselImg[val].style.cssText = "transform:scale(1.2)";
      });
    },
    closeOpenMenu(t) {
      this.$refs.menu.menuShow = true;
    },
  },
};
</script>
<style >
.firstscreen .el-icon-arrow-right:before {
  content: url(../assets/image/home/Context.png) !important;
}
.firstscreen .el-icon-arrow-left:before {
  content: url(../assets/image/home/left.png) !important;
}
.firstscreen .el-carousel__arrow {
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;

  cursor: pointer;
  transition: 0.3s;
  /* border-radius: 50%; */
  background-color: transparent;
  /* color: #FFF; */
  position: absolute;
  top: 90%;
  z-index: 10;
  /* transform: translateY(-50%); */
  text-align: center;
  font-size: 12px;
}
.firstscreen .el-carousel__arrow:hover {
  background: transparent;
}
.firstscreen .el-carousel__arrow--right {
  right: 30px;
  height: 45px;
  width: 164px;
}
.firstscreen .el-carousel__arrow--left {
  left: 30px;
  height: 20px;
  width: 74px;
}
</style>
<style lang="scss" scoped>
.carouselImg {
  width: 100%;
  transition: 15s;
  transform: scale(1);
}

.firstscreen,
.aboutxy,
.aboutxy-mask,
.groupnews,
.corporateculture {
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.firstscreen {
  // padding-top: 50px;
  color: #1c2236;
  .img-box {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    img {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
    .text-box {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      p:nth-child(1) {
        font-size: 84px;
        // font-family: IOS9W4;
        font-weight: bold;
        margin-top: 422px;
        text-shadow: 0.5em 0.2em 0.3em #fff;
      }
      p:nth-child(2) {
        font-size: 40px;
        font-family: Arial;
        font-weight: 400;
        line-height: 11px;
        margin-top: 80px;
        text-shadow: 0.5em 0.2em 0.3em #fff;
      }
    }
  }
}
.aboutxy {
  background-image: url("../assets/image/home/aboutxy.png");
}
.aboutxy-mask {
  background-image: url("../assets/image/home/aboutxy-mask.png");
  .aboutxy-center {
    padding-top: 78px;
    color: #fff;
    max-width: 1466px;
    margin: auto;
    .title {
      font-size: 40px;
      margin-bottom: 17px;
    }
    .introduce {
      line-height: 60px;
      padding: 0 4px;
      margin-bottom: 59px;
    }
    .but-box {
      margin-bottom: 180px;
      .learnmore {
        width: 154px;
        height: 50px;
        color: #fff;
        background: #6c35e4;
        cursor: pointer;
      }
    }
    .strategy {
      padding: 50px 0 70px 0;
      display: flex;
      justify-content: space-between;
      .item {
        width: 384px;
        .subtitle {
          font-size: 30px;
          height: 30px;
          margin: 26px 0px;
        }
        .icon {
        }
        div {
          line-height: 36px;
        }
      }
      .item:nth-child(2) {
        width: 408px;
      }
      .item:nth-child(3) {
        // width: 400px;
      }
    }
  }
}
.groupnews {
  height: 940px;
  background-image: url("../assets/image/home/groupnews.png");
  .groupnews-center {
    padding-top: 78px;
    color: #fff;
    max-width: 1466px;
    margin: 0 auto;
    .title {
      font-size: 40px;
      color: #000508;
      // margin-bottom: 17px;
    }
    .groupnews-line {
      margin-bottom: 36px;
      .center-line {
        width: 70px;
        height: 4px;
        background: #6c35e4;
        border-radius: 2px;
        display: inline-block;
      }
    }
    .groupnews-content {
      color: #000000;
      display: flex;
      justify-content: space-between;
      .left:hover {
        background-size: 108% 108%;
        transition: 0.5s;
      }
      .left {
        width: 968px;
        text-align: left;
        display: inline-block;
        background-image: url("../assets/image/home/news.png");
        background-size: 100% 100%;
        font-family: Microsoft YaHei;
        overflow: hidden;
        height: 580px;
        .text {
          padding: 133px 0 0 78px;
          p:nth-child(1) {
            width: 442px;
            height: 48px;
            font-size: 48px;
            margin-bottom: 40px;
          }
          p:nth-child(2) {
            height: 38px;
            font-size: 39px;
          }
        }
      }
      .right {
        padding-top: 30px;
        width: 468px;
        display: inline-block;
        .top:hover {
          background-size: 108% 108%;
          transition: 0.5s;
        }
        .top {
          background-image: url("../assets/image/home/thankyoumeeting.png");
          background-size: 100% 100%;
          height: 262px;
          margin-bottom: 26px;
          overflow: hidden;
          .text {
            padding: 40px 32px 0 0;
            p {
              text-align: right;
              line-height: 35px;
            }
            p:nth-child(3) {
              font-size: 18px;
            }
          }
        }
        .bottom:hover {
          background-size: 108% 108%;
          transition: 0.5s;
        }
        .bottom {
          background-image: url("../assets/image/home/housewarming.png");
          background-size: 100% 100%;
          height: 262px;
          overflow: hidden;
          .text {
            text-align: left;
            padding: 32px 0 0 22px;
            line-height: 40px;
            p {
              color: #999999;
              font-size: 16px;
            }
            p:nth-child(2) {
              font-size: 24px;
              color: #000;
            }
            p:nth-child(4) {
              line-height: 26px;
            }
            p:nth-child(5) {
              text-align: right;
              padding-right: 27px;
              color: #6c35e4;
              cursor: pointer;
            }
          }
        }
      }
    }
    .more {
      margin: 70px 0 84px 0;
      cursor: pointer;
    }
  }
}
.corporateculture {
  height: 642px;
  background-image: url("../assets/image/home/corporateculture.png");
  position: relative;
  img {
    position: absolute;
    left: 50%;
    margin-left: -90px;
    bottom: 167px;
    cursor: pointer;
  }
  img:hover {
    box-shadow: 0px 4px 5px #8555e8;
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 500px) {
  .firstscreen {
    .text-box {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      p:nth-child(1) {
        font-size: 24px;
        // font-family: IOS9W4;
        font-weight: bold;
        margin-top: 422px;
        text-shadow: 0.5em 0.2em 0.3em #fff;
      }
      p:nth-child(2) {
        font-size: 12px;
        font-family: Arial;
        font-weight: 400;
        line-height: 11px;
        margin-top: 80px;
        text-shadow: 0.5em 0.2em 0.3em #fff;
      }
    }
  }
}


</style>
